.searchBarWrapper {
    @apply flex relative font-italianplate text-black;

    width: 28%;

    @screen sm {
        width: auto;
    }
}

.searchBarForm {
    @apply flex w-full;
}

.storeNameHighlighted {
    @apply font-bold;
}

.searchBarSuggestionWrapper {
    @apply absolute z-max p-4 top-14 rounded-xlg shadow-xs-inner-grey80 w-full bg-white invisible;

    &:hover {
        @apply shadow-xs-inner-black;
    }

    .loadingSpinnerContainer {
        @apply h-100 flex flex-col justify-center items-center;
        div:first-child {
            @apply w-24;
        }
    }
}

.searchBarSuggestionHeading {
    @apply leading-7 font-bold text-lg;
}

.optionListDivider {
    @apply my-4 border-t border-dashed;
}

.searchBarSuggestionOption {
    a {
        @apply block p-2 rounded-xlg leading-snug text-md;

        &:hover {
            @apply bg-mint;
        }
    }

    .searchBarSuggestionOptionActive {
        @apply block p-2 rounded-xlg text-md bg-mint;
    }
}

.searchBarSuggestionSeeMoreLink {
    @apply pt-2;

    a {
        @apply px-2 leading-snug text-md font-bold;

        &:hover {
            @apply underline;
        }
    }
}

.searchBarResultNotFound {
    @apply block px-2 pt-2 text-md text-gray40;
}

.showSuggestionList {
    @apply visible;
}
