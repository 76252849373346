.footer {
    @apply border-t pt-16;

    @screen md {
        @apply pt-12;
    }

    @screen sm {
        @apply mb-16 pt-4;
    }
}

.col {
    @apply row-start-1 col-span-2 text-sm;

    @screen md {
        @apply row-start-auto col-span-2;
    }

    @screen sm {
        @apply row-start-auto mb-3;
    }
}

.localeSwitcher {
    @apply row-start-1 col-span-2 text-sm;

    @screen md {
        @apply row-start-auto col-start-7 col-span-2;
    }

    @screen sm {
        @apply row-start-auto col-span-4;
    }
}

.colSmall {
    @apply row-start-2 col-span-2 text-xs mt-4;

    @screen md {
        @apply row-start-auto col-span-2;
    }

    @screen sm {
        @apply row-start-auto col-span-1;
    }
}

.colHeader {
    @apply mb-4;
}

.legalText {
    @apply row-start-2 col-span-4 text-xs mt-4;

    a {
        @apply underline text-initial;
    }
    @screen md {
        @apply row-start-auto col-span-8;
    }

    @screen sm {
        @apply row-start-4 col-span-4;
    }
}

.acknowledgementText {
    @apply row-start-3 col-span-full text-xs mt-4;
}

.logo {
    @apply row-start-4 w-28 mt-10 mb-20;

    @screen md {
        @apply row-start-auto;
    }

    @screen sm {
        @apply row-start-6 mt-8;
    }
}
